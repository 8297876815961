.mainBox {
  background: #000000;
  border: 6px solid rgba(255, 255, 255, 0.17);
  width: 100%;
  position: relative;
  padding: 34px 0 60px;
  box-sizing: border-box;
  overflow: hidden;
}

.boxContent {
  position: relative;
  z-index: 10;
}

.topBlockie {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.downBlockie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: 512px) {
  .mainBox {
    max-height: 80%;
    padding: 34px 0 60px;
  }
}
