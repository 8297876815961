.title {
  font-size: 36px;
  font-family: Joystix;
  color: #a6ff00;
  line-height: 36px;
  letter-spacing: 1px;
  text-align: center;
  a{
    color: #a6ff00;
  }
}

.description {
  padding: 32px 20px 0;
}

.listItem {
  font-size: 16px;
  font-family: Joystix;
  color: #ffffff;
  line-height: 24px;
  letter-spacing: 0.5px;
  position: relative;
  padding: 0 0 20px 32px;
}

.listItemWrapper {
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 16px;

  img {
    width: 100%;
    height: 100%;
  }
}

.getStart {
  margin: 0 auto;
  width: 135px;

  img {
    width: 100%;
    height: 100%;
  }
}

.apeInContainer {
  margin: 16px 48px;
  padding: 16px 24px;
  background: #000000;
  border: 4px solid;
  border-image: linear-gradient(
      180deg,
      rgba(121, 76, 232, 1),
      rgba(231, 76, 175, 1)
    )
    4 4;
}

.guide {
  font-size: 24px;
  font-family: Joystix;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  padding: 0 0 20px 0;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  width: 200px;
}


@media screen and (max-width: 512px) {
  .title {
    font-size: 28px;
  }
  
  .listItem {
    font-size: 14px;
  }
  
  .listItemWrapper {
    width: 14px;
    height: 14px;
  }
  
  .guide {
    font-size: 20px;
  }

  .apeInContainer {
    margin: 16px 10px;
  }
}