.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px 43px;
  box-sizing: border-box;
}

.left {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  a {
    display: flex;
    align-items: flex-end;
  }

  .logoImg {
    display: inline-block;
    height: 32px;
  }

  .textImg {
    display: inline-block;
    height: 10px;
    margin: 0 0 0 6px;
  }
}

.right {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

@media screen and (max-width: 512px) {
  .row {
    padding: 8px 12px;
  }

  .left {
    a {
      flex-direction: column;
      display: flex;
      align-items: center;
    }
  
    .logoImg {
      display: inline-block;
      height: 24px;
      margin: 0 0 5px 0;
    }
  
    .textImg {
      height: 10px;
      margin: 0 0 0 6px;
    }
  }

  .right {
    font-size: 14px;
  }
}
