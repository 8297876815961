@font-face {
  font-family: Joystix;
  src: url('assets/font/Joystix.ttf');
}

html {
  width: 100%;
  height: 100%;
  font-family: Montserrat, -apple-system, system-ui, 'Helvetica Neue',
    'PingFang SC', 'Microsoft YaHei', 'Source Han Sans SC', 'Noto Sans CJK SC',
    'WenQuanYi Micro Hei', sans-serif !important;
  font-size: 14px;
}

body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Montserrat, -apple-system, system-ui, 'Helvetica Neue',
    'PingFang SC', 'Microsoft YaHei', 'Source Han Sans SC', 'Noto Sans CJK SC',
    'WenQuanYi Micro Hei', sans-serif !important;
  font-size: 14px !important;
}

ul {
  margin: 0;
  padding: 0;
}

.bn-onboard-modal {
  z-index: 999 !important;
}

.bn-onboard-select-info-container {
  display: none !important;
}

.bn-onboard-modal-content-header-heading {
  color: rgba(255,255,255,1);
}