.titleContainer {
  margin: 0 0 12px 0;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 0.5fr;
  grid-column-gap: 56px;
  padding: 0 20px 0 32px;
}
.scoreWrapperTitle,
.creatureWrapperTitle {
  font-size: 16px;
  font-family: Joystix;
  color: #ffffff;
  line-height: 22px;
  margin: 0 0 12px 0;
  text-align: center;
}

.scoreWrapperScore {
  font-size: 36px;
  font-family: Joystix;
  color: #a6ff00;
  line-height: 36px;
  letter-spacing: 1px;
  text-align: center;
}

.creatureWrapperCreature {
  font-size: 36px;
  font-family: Joystix;
  color: #ea4e34;
  line-height: 36px;
  letter-spacing: 1px;
  text-align: center;
}

.nftContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 16px;

  img {
    width: 160px;
    height: 160px;
  }
}

.scoreDetailContainer {
  background: #000000;
  border: 1px solid #191919;
  padding: 12px 20px;
  overflow: auto;
  height: 200px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 13px;
    border: 1px solid #191919;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background-color: rgba(255, 255, 255, 0.65);
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
    background-clip: content-box;
  }
}

.detailItem {
  padding: 16px;
  background: #000000;
  border: 1px solid #191919;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .detailTitle {
    font-size: 16px;
    font-family: Joystix;
    color: #ffffff;
    line-height: 20px;
  }

  .detailReason {
    padding: 8px 0 0 0;
    font-size: 12px;
    font-family: Joystix;
    color: #e64cb0;
    line-height: 16px;
    background: linear-gradient(90deg, #9ce6ff 0%, #5fc6fd 52%, #f72c8b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .detailScore {
    font-size: 24px;
    font-family: Joystix;
    color: #a6ff00;
    line-height: 28px;
    letter-spacing: 1px;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 16px 0 12px;
}

.button {
  width: 200px;
}

.notClaimReason {
  margin: 8px 0 0 0;
  font-size: 10px;
  font-family: Joystix;
  color: #df3a1c;
  line-height: 12px;
}

.specificationContainer {
  padding: 12px 36px 0;
  font-size: 12px;
  font-family: Joystix;
  color: #a8a8a8;
  line-height: 16px;

  div + div {
    margin: 6px 0 0 0;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
}

.contactContainer {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Joystix;
  color: #ffffff;
  line-height: 20px;
  padding: 12px 36px;

  a {
    margin: 0 0 0 24px;
  }

  img {
    width: 48px;
    height: 48px;
  }
}

@media screen and (max-width: 512px) {
  .titleContainer {
    grid-column-gap: 10px;
    padding: 0 5px 0 10px;
  }

  .scoreWrapperTitle,
  .creatureWrapperTitle {
    font-size: 14px;
  }

  .scoreWrapperScore,
  .creatureWrapperCreature {
    font-size: 22px;
  }

  .nftContainer {
    img {
      width: 100px;
      height: 100px;
    }
  }

  .scoreDetailContainer {
    padding: 8px 12px;
  }

  .detailItem {
    .detailTitle {
      font-size: 14px;
    }

    .detailScore {
      font-size: 18px;
    }
  }
  .specificationContainer {
    padding: 12px 20px 0;
  }

  .contactContainer {
    font-size: 14px;
    padding: 12px 20px;

    a {
      margin: 0 0 0 24px;
    }

    img {
      width: 36px;
      height: 36px;
    }
  }
}
