.container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.medium {
    padding: 8px 0;
    .text {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.primary {
    background: #2539f4;
    overflow: hidden;
    color: #fff;
    border: none;
    box-shadow: none;

    &:hover {
      background: #372dc8;
    }

    &.disabled {
      background: #5b5b5b;
      color: rgba(255,255,255, 0.5)
    }
  }

  &.error {
    overflow: hidden;
    border: none;
    background: #f5222d;
    color: #fff;

    &:hover {
    }

    &.disabled {
      opacity: 0.25;
      cursor: not-allowed;
    }
  }
}

.text {
  font-family: Joystix;
  color: #ffffff;
}
