.app {
  width: 100%;
  min-height: 100%;
  background: url("./assets/img/bg@2x.png") no-repeat center center;
  background-size: cover;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 560px;
  overflow: auto;
  margin: 0px auto;
  padding: 20px 10px 0;
}

@media screen and (max-width: 512px) {
  .app {
    background: url("./assets/img/bg.png") no-repeat center center;
    background-size: cover;
  }

  .main {
    padding: 32px 10px;
  }
}
